var renderer = new THREE.WebGLRenderer({
	canvas: document.getElementById("canvas"),
	antialias: true,
  alpha: true
});

var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(45,window.innerWidth/window.innerHeight,0.1,1000);
camera.position.z = 4.7;

const ww = window.innerWidth;
const spw = 767;

if(ww>spw){
	renderer.setSize(window.innerWidth/1.1, window.innerHeight);
	window.onresize = function() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth/1.1, window.innerHeight);
	};
}else {
	renderer.setSize(window.innerWidth, window.innerHeight);
	window.onresize = function() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
	};
}



var geo = new THREE.SphereGeometry(1, 64, 64);
// var geo = new THREE.CircleGeometry( 120, 120);
// var geom = new THREE.TorusKnotGeometry(2.5, .5, 100, 16);
var mat = new THREE.MeshNormalMaterial();
// const mat = new THREE.MeshBasicMaterial(0x00d8ff);
// var positions = new Float32Array( 1 * 64 * 64 ); // 頂点座標
// var colors = new Float32Array( 1 * 64 * 64 ); // 頂点色
// console.log(positions);
// console.log(colors);
// const color = new THREE.Color(0x000000);
// var color = new THREE.Color("hsl(0, 100%, 50%)");

// var rev = true;
// geo.computeBoundingBox();
// var material = new THREE.MeshPhongMaterial({
//   uniforms: {
//     color1: {
//       value: new THREE.Color("red")
//     },
//     color2: {
//       value: new THREE.Color("purple")
//     },
//     bboxMin: {
//       value: geo.boundingBox.min
//     },
//     bboxMax: {
//       value: geo.boundingBox.max
//     }
//   },
//   vertexShader: `
//     uniform vec3 bboxMin;
//     uniform vec3 bboxMax;
//
//     varying vec2 vUv;
//
//     void main() {
//       vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
//       gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
//     }
//   `,
//   fragmentShader: `
//     uniform vec3 color1;
//     uniform vec3 color2;
//
//     varying vec2 vUv;
//
//     void main() {
//
//       gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
//     }
//   `,
//   wireframe: true
// });

var mesh = new THREE.Mesh(geo, mat);

scene.add(mesh);

var update = function() {
  for (var i=0; i<mesh.geometry.vertices.length; i++) {
  	var v = mesh.geometry.vertices[i];
  	v.normalize().multiplyScalar(0.08*noise.simplex3(v.x*0.8+Date.now()*0.0001, v.y*0.8-Date.now()*0.0001, v.z*0)+1);
  }
  // mesh.geometry.computeVertexNormals();
  // mesh.geometry.normalsNeedUpdate = false;
  mesh.geometry.verticesNeedUpdate = true;
};

function render() {
update();
renderer.render(scene, camera);
requestAnimationFrame(render);
}
render();

//=======================================================
//▼mv slider
//-------------------------------------------------------

//get slider_cmn
const mv_slider_cmn = document.querySelectorAll('.mv__slider__cmn');
//set index count
let count = 0;

const mvSlider = () =>{
	//none next index
	if(mv_slider_cmn[count] == null){
		let cn = count - 1;
		mv_slider_cmn[cn].classList.remove('active');
		//reset count
		count = 0;
	}

	if(count !== 0){
		let cn = count - 1;
		mv_slider_cmn[cn].classList.remove('active');
	}
	mv_slider_cmn[count].classList.add('active');
	count++;


	setTimeout(mvSlider, 8000);
}
mvSlider();
